.analytics{
    width: 100%;
    padding: 1rem 8rem;
    margin-bottom: 40px;
    @media screen and (max-width: 800px) {
        padding: 0 1rem;
    }
    .chart{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .tokens{
        margin-top: 20px;
        .ant-tag{
          position: absolute;
          top: 0;
          right: -10px;
        }
    }
    @media screen and (max-width: 800px) {
        .three-line-legend {
          display: none !important;
        }
      }
      
    .tv-lightweight-charts{
        width: 100% !important;
        
      
        & > * {
          width: 100% !important;
        }
      }
  .networks{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .tokenTable{
    .ant-table tbody tr{
      cursor: pointer;
    }
  }
}
.tokenPage{
  width: 100%;
  padding: 1rem 8rem;
  margin-bottom: 40px;
  @media screen and (max-width: 800px) {
    padding: 0 1rem;
  }
}